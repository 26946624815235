export class UploadToS3Service {
  async uploadSingleFileAndUpdate(file: File, document: any) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('original_file_name', file.name);
      formData.append('file_content_type', file.type);
      formData.append('storage_size', file.size.toString());

      const response = await fetch(`/api/v1/documents/${document.id}/upload_attachment`, {
        method:      'PUT',
        credentials: 'include',
        body:        formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Upload failed');
      }

      const data = await response.json();
      return data.document;
    } catch (error) {
      throw error;
    }
  }

  async uploadFileToS3({ file, s3ObjectKey, document }: {
    file: File,
    s3ObjectKey: string,
    document: any
  }) {
    try {
      // 1. Configure AWS
      AWS.config.update({ region: window.configData.cognitoRegion });
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: window.configData.cognitoPoolId,
      });

      // 2. Wait for credentials
      await new Promise((resolve, reject) => {
        (AWS.config.credentials as AWS.CognitoIdentityCredentials).get(() => {
          resolve(true);
        });
      });

      // 3. Update region for S3
      AWS.config.update({ region: window.configData.aws_main_bucket_region });

      // 4. Create S3 instance
      const bucket = new AWS.S3({
        params: {
          Bucket: window.configData.bucketName,
        },
        config: {
          signatureVersion: 'v4',
        },
      });

      // 5. Upload to S3
      await new Promise((resolve, reject) => {
        const params = {
          Key:    s3ObjectKey,
          Bucket: window.configData.bucketName,
          Body:   file,
        };

        bucket.upload(params, (err, data) => {
          if (err) reject(err);
          else resolve(data);
        });
      });
    } catch (error) {
      throw error;
    }
  }
}
