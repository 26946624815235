import React, { useCallback, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

import toastr from '@lib/toastr';
import { useUploadBankStatementAttachment } from '@src/hooks/bank_statements';
import { useUploadMailroomDocument, useDeleteDocument } from '@src/hooks/queries/documents';
import { downloadDocument } from '@src/requests/documents';
import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IDocument } from '@src/types/documents';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { Button } from '@src/components/ui/buttons';

import styles from './document_list.module.scss';

interface IBackboneCollection {
  models: any[];
  findWhere: (attrs: any) => any;
  at: (index: number) => any;
  length: number;
  _onModelEvent: (event: string, model: any, collection: any, options: any) => void;
}

interface IBankStatementWithDocytId extends IBankStatement {
  docyt_id?: string;
}

interface IDocumentModel {
  id: TID;
  business_id: TID;
  bank_statement: IBankStatement | null;
  collection?: IBackboneCollection;
  isBankStatementRequest: () => boolean;
  isBalanceSheetStatementRequest: () => boolean;
  isMailroomRequest: () => boolean;
  get: (key: string) => string | number | null;
}

interface DocumentUploaderProps {
  model: IDocumentModel;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
  documents: IDocument[];
  setDocuments?: (documents: IDocument[]) => void;
  noStatementAvailable: boolean;
  setNoStatementAvailable: (value: boolean) => void;
  onDocumentsUpdate?: (docs: IDocument[]) => void;
  bankStatement: IBankStatementWithDocytId;
  balanceSheetStatements: IBalanceSheetStatement[];
  isReviewed?: boolean;
}

const DocumentUploader: React.FC<DocumentUploaderProps> = ({
  model,
  isUploading,
  setIsUploading,
  documents,
  bankStatement,
  balanceSheetStatements,
  noStatementAvailable,
  setNoStatementAvailable,
  onDocumentsUpdate,
  isReviewed = false,
}) => {
  const [selectedDocId, setSelectedDocId] = useState<TID>(0);
  const uploadAttachment = useUploadBankStatementAttachment();
  const navigate = useNavigate();

  const isMailroomRequest = model.isMailroomRequest();
  const isBankStatementRequest = model.isBankStatementRequest();
  const isBalanceSheetRequest = model.isBalanceSheetStatementRequest();

  const validateBankStatementFileFormat = useCallback((file: File): boolean => {
    const allowedSize = window.configData.allowed_file_upload_size;
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (file.size > allowedSize) {
      toastr.error(`File size should be less than ${allowedSize}`, 'Something went wrong');
      return false;
    }

    if (!fileExtension || !window.configData.allowed_file_upload_format.includes(fileExtension)) {
      toastr.error(
        'File you uploaded is not supported. You can only upload in one of these formats: pdf',
        'Something went wrong',
      );
      return false;
    }

    return true;
  }, []);

  const uploadBankStatement = useCallback(async (files: File[]): Promise<IBankStatement[]> => {
    setIsUploading(true);
    try {
      const uploadPromises = files.map(async (file) => {
        if (!validateBankStatementFileFormat(file)) {
          return null;
        }

        try {
          const response = await uploadAttachment.mutateAsync({
            businessId:      model.get('business_id') as number,
            bankStatementId: bankStatement.id,
            file,
          });

          if (response.bankStatement) {
            const newDocument: IDocument = {
              id:                        response.bankStatement.id,
              name:                      response.bankStatement.name || '',
              docytId:                   response.bankStatement.docytId || '',
              chatId:                    0,
              consumerId:                0,
              current:                   true,
              businessDocuments:         [],
              businessNames:             [],
              businesses:                [],
              createdAt:                 new Date().toISOString(),
              documentOwners:            [],
              source:                    '',
              state:                     'uploaded',
              storageSize:               0,
              unencryptedDocumentFields: [],
              updatedAt:                 new Date().toISOString(),
              uploaderEmail:             '',
              uploaderName:              '',
              dueOff:                    false,
              expiryOff:                 false,
              haveAccess:                true,
              isFrozen:                  false,
              pages:                     [],
              sharers:                   [],
              lastModifiedAt:            new Date().toISOString(),
              computed_final_filename:   null,
              final_file_key:            null,
              original_file_key:         null,
              original_file_name:        null,
            };

            // Update documents list
            onDocumentsUpdate?.([...(documents || []), newDocument]);
            return response.bankStatement;
          }
          return null;
        } catch {
          toastr.error('Failed to upload file', 'Error');
          return null;
        }
      });

      const results = await Promise.all(uploadPromises);
      const successfulUploads = results.filter((r): r is IBankStatement => r !== null);

      if (successfulUploads.length > 0) {
        toastr.success('Files uploaded successfully', 'Success');
      }

      return successfulUploads;
    } catch {
      toastr.error('Upload failed', 'Error');
      return [];
    } finally {
      setIsUploading(false);
    }
  }, [model, bankStatement?.id, documents, onDocumentsUpdate, setIsUploading,
    uploadAttachment, validateBankStatementFileFormat]);

  const deleteDocument = useDeleteDocument();

  const deleteModal = useConfirmDeleteModal({
    onDone: async (confirmed: boolean) => {
      if (confirmed && selectedDocId) {
        try {
          await deleteDocument.mutateAsync({ documentId: selectedDocId });
          const updatedDocs = documents.filter((doc) => doc.id !== selectedDocId);
          onDocumentsUpdate?.(updatedDocs);
          toastr.success('Document deleted successfully', 'Success');
        } catch {
          toastr.error('Failed to delete document', 'Error');
        }
      }
    },
  });

  // const handleMoveToNextRequest = useCallback(() => {
  //   // First uncheck current item
  //   window.Docyt.vent.trigger('request:item:unselected');

  //   // Filter documents based on current state
  //   const currentState = model.get('state');
  //   const filteredRequests = model.collection?.models.filter((req) => {
  //     if (currentState === 'reviewed') {
  //       return req.get('state') === 'reviewed';
  //     }
  //     return req.get('state') === 'opened';
  //   }) || [];

  //   const currentIndex = filteredRequests.findIndex((req) => req.id === model.id);

  //   if (currentIndex >= 0 && currentIndex < filteredRequests.length - 1) {
  //     const nextRequest = filteredRequests[currentIndex + 1];

  //     // Find and click the actual DOM element
  //     const nextRequestElement = document.querySelector(
  //       `tr[data-request-id="${nextRequest.id}"] td.request-detail-column`,
  //     );
  //     if (nextRequestElement) {
  //       (nextRequestElement as HTMLElement).click();
  //     } else {
  //       // Fallback to event triggers if element not found
  //       window.Docyt.vent.trigger('request:item:clicked', nextRequest);
  //       window.Docyt.vent.trigger('active:request:item:changed', nextRequest);
  //     }
  //   } else {
  //     // If no next request, close the side view
  //     window.Docyt.vent.trigger('client:central:right:side:closed');
  //   }
  // }, [model]);

  const uploadMailroomDocument = useUploadMailroomDocument();

  const uploadMailroomDocuments = useCallback(async (files: File[]): Promise<IDocument[]> => {
    try {
      const uploadPromises = files.map((file) => uploadMailroomDocument.mutateAsync({
        file,
        businessId:        model.get('business_id') as TID,
        documentRequestId: model.id,
      }));

      const uploadedDocuments = await Promise.all(uploadPromises);

      uploadedDocuments.forEach((uploadedDocument) => {
        if (onDocumentsUpdate) {
          const updatedDocs = [...(documents || []), uploadedDocument];
          onDocumentsUpdate(updatedDocs);
        }
        window.Docyt.vent.trigger('document:uploaded', uploadedDocument);
      });

      if (uploadedDocuments.length > 0) {
        toastr.success('Files uploaded successfully', 'Success');
      }

      return uploadedDocuments;
    } catch {
      toastr.error('Failed to upload documents', 'Error');
      return [];
    }
  }, [model, documents, onDocumentsUpdate, uploadMailroomDocument]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      if (isUploading) return;

      try {
        setIsUploading(true);

        if (isMailroomRequest) {
          // Handle mailroom documents separately
          await uploadMailroomDocuments(acceptedFiles);
        } else {
          // Handle bank statement/balance sheet uploads
          if (acceptedFiles.length > 1) {
            toastr.error('Only one file can be uploaded at a time', 'Error');
            return;
          }

          if ((isBankStatementRequest || isBalanceSheetRequest) && documents.length > 0) {
            return;
          }
          const uploadedDocs = await uploadBankStatement([acceptedFiles[0]]);
          if (uploadedDocs.length > 0) {
            window.Docyt.vent.trigger('transactions:request:done');
          }
        }
      } catch {
        toastr.error('Upload failed', 'Error');
      } finally {
        setIsUploading(false);
      }
    },
    multiple: isMailroomRequest,
    accept:   '.pdf,application/pdf',
  });

  const renderDocumentTable = useCallback(() => {
    let documentsToShow: any[] = [];

    const handleDocumentClick = async (e: React.MouseEvent, docId: number, type: string) => {
      e.preventDefault();
      e.stopPropagation();

      if (type === 'bank_statement') {
        navigate(`/businesses/${model.get('business_id')}/documents/${docId}`);
        return;
      }

      try {
        toastr.success('Downloading document...', 'Download in progress');
        await downloadDocument({
          documentId: docId,
          type:       'original',
        });
        toastr.success('Document downloaded successfully', 'Success');
      } catch {
        toastr.error('Document PDF not ready', 'Warning');
      }
    };

    // Show documents if request is reviewed OR if it's an unreviewed mailroom request
    // if (isReviewed || (!isReviewed && model.isMailroomRequest())) {
    if (model.isBankStatementRequest() && documents.length > 0) {
      documentsToShow = [{
        id:      bankStatement.id,
        docytId: bankStatement.docyt_id || bankStatement.docytId || '',
        name:    bankStatement.name || 'Bank Statement',
        type:    'bank_statement',
        state:   bankStatement.state,
        fileUrl: bankStatement.statementFileUrl,
      }];
    }
    if (model.isBalanceSheetStatementRequest()) {
      const nonRequestedStatements = balanceSheetStatements?.filter((stmt) => 
        stmt.state !== window.Docyt.Common.Constants.BALANCE_SHEET_STATEMENT_STATES.REQUESTED);

      if (nonRequestedStatements?.length > 0) {
        documentsToShow = nonRequestedStatements.map((stmt) => ({
          id:      stmt.id,
          docytId: stmt.docytId || '',
          name:    stmt.name || 'Balance Sheet',
          type:    'balance_sheet',
          state:   stmt.state,
        }));
      }
    }
    if (model.isMailroomRequest()) {
      documentsToShow = documents.map((doc) => ({
        id:      doc.id,
        docytId: doc.docytId || `DOC-${doc.id}`,
        name:    doc.name || doc.originalFileName || doc.originalFileKey || doc.computedFinalFilename
        || doc.original_file_name || doc.final_file_key || doc.computed_final_filename || 'Document',
        type:  'document',
        state: doc.state,
      }));
      // }
    }

    if (!documentsToShow.length) {
      // Only show "No document available" if:
      // 1. Request is reviewed OR
      // 2. No Statement Available checkbox is checked
      if (isReviewed || noStatementAvailable) {
        return (
          <div className={ styles.documentsTable }>
            <div className={ styles.noDocumentsMessage }>
              No document available
            </div>
          </div>
        );
      }
      return null;
    }

    return (
      <div className={ styles['documents-table'] }>
        <table aria-label="Documents">
          <thead>
            <tr>
              <th scope="col">Document ID</th>
              <th scope="col">Name</th>
              <th scope="col">Amount</th>
              <th scope="col"><span className="sr-only">Actions</span></th>
            </tr>
          </thead>
          <tbody>
            {documentsToShow.map((doc) => (
              <tr key={ doc.id }>
                <td className={ styles['document-id'] }>
                  {(() => {
                    if (model.isMailroomRequest()) {
                      return (
                        <a
                          className=""
                          href={ `/businesses/${model.get('business_id')}/documents/${doc.id}` }
                          onClick={ (e) => handleDocumentClick(e, doc.id, doc.type) }
                        >
                          {doc.docytId}
                        </a>
                      );
                    }

                    return (
                      <span>
                        {doc.docytId}
                      </span>
                    );
                  })()}
                </td>
                <td>{doc.name}</td>
                <td>-</td>
                <td>
                  {(!isReviewed && isMailroomRequest) && (
                    <Button
                      aria-label={ `Delete document ${doc.name}` }
                      className={ styles['delete-button'] }
                      onClick={ () => {
                        setSelectedDocId(doc.id);
                        deleteModal.open();
                      } }
                    >
                      <i aria-hidden="true" className="fa fa-trash" />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <deleteModal.Component
          confirmStyle="primary"
          confirmTitle="Delete"
          text="Are you sure you want to delete this document?"
          title="Delete Document"
          { ...deleteModal.props }
        />
      </div>
    );
  }, [documents, deleteModal, isReviewed, isMailroomRequest, bankStatement,
    balanceSheetStatements, model, navigate, noStatementAvailable]);

  const renderDropZone = () => {
    // Don't render dropzone if request is reviewed
    if (isReviewed) {
      return null;
    }

    return (
      <div
        { ...getRootProps() }
        className={ `${styles['document-drop-zone']} ${isDragActive ? styles.active : ''}` }
      >
        <input { ...getInputProps() } />
        <div className={ styles['drop-zone-content'] }>
          <span className={ styles['add-document-text'] }>Add Document</span>
          <span className={ styles['drag-drop-text'] }>
            {isUploading && 'Uploading...'}
            {!isUploading && isMailroomRequest && 'Drag and drop your files here'}
            {!isUploading && !isMailroomRequest && 'Drag and drop your file here'}
          </span>
        </div>
      </div>
    );
  };

  const renderNoStatementCheckbox = () => {
    // Only show for non-mailroom requests AND when no documents exist
    if (!model.isMailroomRequest() && documents.length === 0) {
      return (
        <div className={ styles['no-statement-checkbox'] }>
          <label className={ styles['no-statement-checkbox-label'] }>
            <input
              checked={ noStatementAvailable }
              type="checkbox"
              onChange={ (e) => setNoStatementAvailable(e.target.checked) }
            />
            <span>No Statement Available</span>
          </label>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {renderDocumentTable() }
      {!isReviewed && (
        <>
          {renderDropZone()}
          {renderNoStatementCheckbox()}
        </>
      )}
    </div>
  );
};

export default DocumentUploader;
