import { useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetIntegrationExternalSystemConfig } from '@src/hooks/queries/integrations/integrations';
import { TID } from '@src/types/common';

const useGetIntegrationExternalSystemConfigData = (externalSystemId: TID) => {
  const business = useBusinessContext();
  const externalSystem = useGetIntegrationExternalSystemConfig({
    businessId: business.id,
    externalSystemId,
  });

  const externalSystemConfig = useMemo(() => {
    return externalSystem.data?.externalSystemConfig;
  }, [externalSystem.data]);

  return externalSystemConfig;
};

export {

  useGetIntegrationExternalSystemConfigData,
};
