import { useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import {
  useGetIntegrationExternalSystem,
} from '@src/hooks/queries/integrations/integrations';

const useGetIntegrationExternalSystemData = () => {
  const business = useBusinessContext();
  const externalSystem = useGetIntegrationExternalSystem({
    businessId:         business.id,
    standardCategoryId: business.standardCategoryId,
  });

  const externalSystemList = useMemo(() => {
    return (
      // TODO add connected if data already from backend
      externalSystem.data?.collection?.map((value) => ({
        label:           value.description,
        id:              value.id,
        connected:       0,
        integrationType: value.integrationType,
        configSchema:    value.configSchema,
      })) || []
    );
  }, [externalSystem.data]);

  return externalSystemList;
};

export {
  useGetIntegrationExternalSystemData,
};
