import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getIntegrationAuthorizationUrl,
  IGetIntegrationAuthorizationUrlParams,
  IGetIntegrationAuthorizationUrlResponse,
} from '@src/requests/integrations/integration_authorizations';
import {
  IGetIntegrationParams,
  getIntegration,
  IDisconnectIntegrationParams,
  disconnectIntegration,
  ISetIntegrationPausedParams,
  setIntegrationPaused,
  UpsertIntegration,
  IUpsertIntegrationParams,
  IUpsertIntegrationResponse,
  IGetIntegrationExternalSystemParams,
  getIntegrationExternalSystem,
  IGetIntegrationExternalSystemResponse,
  IGetIntegrationExternalSystemConfigParams,
  getIntegrationExternalSystemConfig,
  IGetIntegrationExternalSystemConfigResponse,
} from '@src/requests/integrations/integrations';
import { IIntegration } from '@src/types/integrations/integrations';

const useGetIntegration = (params: IGetIntegrationParams, options?: { enabled?: boolean }) => {
  return useQuery<IIntegration, Error>(
    [QueryKey.integration, params.integrationId],
    () => getIntegration(params),
    {
      enabled: options?.enabled ?? true,
    },
  );
};

const useDisconnectIntegration = () => {
  return useMutation<void, Error, IDisconnectIntegrationParams>(disconnectIntegration);
};

const useSetIntegrationPaused = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, ISetIntegrationPausedParams>(
    (params) => setIntegrationPaused(params),
    {
      onSuccess: (data, variables) => {
        const { integrationId, isPaused } = variables;
        queryClient.setQueryData([QueryKey.integration, integrationId], (integration: any) => {
          return {
            ...integration,
            isEnabled: !isPaused,
          };
        });
      },
    },
  );
};

const useUpsertIntegration = () => {
  return useMutation<IUpsertIntegrationResponse, Error, IUpsertIntegrationParams>(
    UpsertIntegration,
  );
};

const useGetIntegrationExternalSystem = (params: IGetIntegrationExternalSystemParams) => {
  return useQuery<IGetIntegrationExternalSystemResponse, Error>(
    [QueryKey.integration, params],
    () => getIntegrationExternalSystem(params),
  );
};

const useGetIntegrationExternalSystemConfig = (params: IGetIntegrationExternalSystemConfigParams) => {
  return useQuery<IGetIntegrationExternalSystemConfigResponse, Error>(
    [QueryKey.integration, params],
    () => getIntegrationExternalSystemConfig(params),
  );
};

const useGetIntegrationAuthorizationUrl = (params: IGetIntegrationAuthorizationUrlParams) => {
  return useQuery<IGetIntegrationAuthorizationUrlResponse, Error>(
    [QueryKey.integration, params],
    () => getIntegrationAuthorizationUrl(params),
  );
};

export {
  useGetIntegration,
  useDisconnectIntegration,
  useSetIntegrationPaused,
  useUpsertIntegration,
  useGetIntegrationExternalSystem,
  useGetIntegrationExternalSystemConfig,
  useGetIntegrationAuthorizationUrl,
};
