import React, { useCallback } from 'react';

import classNames from 'classnames';
import { ControllerRenderProps } from 'react-hook-form';

import { useUniqueDomId } from '@src/hooks/dom';
import { IWidget } from '@src/types/dashboards';

import { AmountInput, NumberInput, PercentageInput } from '@src/components/ui_v2/inputs';

import { IEditTargetsFormValues } from './schema';

import styles from './styles.module.scss';

interface IEditTargetsItemProps
{
  widget: IWidget,
  field: ControllerRenderProps<IEditTargetsFormValues, 'targets'>,
  defaultTarget: number,
  targetUnit: string,
}

const EditTargetsItem = ({
  widget,
  field: { value, onChange },
  defaultTarget,
  targetUnit,
}: IEditTargetsItemProps): JSX.Element => {
  const inputId = useUniqueDomId('target_selector_');

  const handleChange = useCallback((v) => {
    value[widget.identifier] = v;
    onChange(value);
  }, [value, widget.identifier, onChange]);

  const handleReset = useCallback((event: any) => {
    event.preventDefault();
    event.stopPropagation();
    value[widget.identifier] = defaultTarget;
    onChange(value);
  }, [value, defaultTarget, onChange, widget.identifier]);

  return (
    <div className={ classNames('row', styles['edit-targets-item']) }>
      <label
        className="col-md-4"
        htmlFor={ inputId }
      >
        { widget.name }
      </label>
      <div className="col-md-4">
        { targetUnit === 'percent' && (
          <PercentageInput
            id={ widget.identifier }
            maxLength={ 10 }
            value={ value[widget.identifier] || 0 }
            onChange={ handleChange }
          />
        )}
        { targetUnit === 'dollar' && (
          <AmountInput
            id={ widget.identifier }
            maxLength={ 20 }
            value={ value[widget.identifier]?.toString() || '' }
            onChange={ handleChange }
          />
        )}
        { targetUnit === 'count' && (
          <NumberInput
            id={ widget.identifier }
            maxLength={ 10 }
            value={ value[widget.identifier] || 0 }
            onChange={ handleChange }
          />
        )}
      </div>
      <div className="col-md-4">
        <div>
          <a
            className="pointer"
            href="#"
            onClick={ handleReset }
          >
            Reset to Default
          </a>
        </div>
        <span>
          { targetUnit === 'dollar' && `$${defaultTarget.toFixed(2)}` }
          { targetUnit === 'percent' && `${defaultTarget.toFixed(2)}%` }
          { targetUnit === 'count' && `${defaultTarget}` }
        </span>
      </div>
    </div>
  );
};

export default EditTargetsItem;
